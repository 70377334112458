import React, { useEffect, useState } from 'react'
import {BrowserRouter, Routes, Route, Outlet, useLocation, useNavigate} from 'react-router-dom';
import SideNav from './sideNav';
import TopBar from './topBar';
import Cookie from 'react-cookies';
import { useSelector } from 'react-redux';
import { uData } from '../../api/api';
const Nav = () => {
    const navigate = useNavigate();
    const {user} = useSelector(state => state);
    const [readyState, setReadyState] = useState(true)

    function checkState(){
      window.start_load();
      const u = user.payload;
      const token = Cookie.load('token') ? Cookie.load('token'): '';
      if(token.length > 0){
        if(u.id != undefined){
          // console.log('user exist')
          setReadyState(true)
        }else{
          // console.log('user does not exist')
          uData(navigate, token);
        }
      }else{
        // console.log('token condition not met')
       navigate('/login');
      }
     window.stop_load()
    }
    
    useEffect(() => {
        // console.log(status())
        checkState();
        // console.log(user)
    }, [user])
  return readyState && (
    <div className=''>
       <SideNav />
       <div class="home-section">
            <TopBar notification />
            {/* {console.log()} */}
           <div className='mt-5'>
              <Outlet />
           </div>
           
        </div>
    </div>
  )
}

export default Nav