// import './App.css';
import Home from './pages/home';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './assets/css/universal.css';
import './assets/css/styles.css';
import ScrollToTop from './assets/jss/scrollTop';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Portal imports
import Login from './pages/portal/Login';
import NavBar from './pages/portal/navBar';
import Dashboard from './pages/portal/dashboard';
import Nav from './pages/portal/home';
import ContactUs from './pages/contactUs';
import AboutUs from './pages/aboutUs';
import Careers from './pages/careers';
import { setLoader } from './pages/components/loader';
import Users from './pages/portal/user';
// import AddUser from './pages/portal/addUser';
// import Dashboard from './pages/portal/dashboard';
// import Users from './pages/portal/user';
// import Nav from './pages/portal/home';
import CreateUser from './pages/portal/newUser';
import AddStoreItem from './pages/portal/addStoreItem';
import UpdateStoreItem from './pages/portal/updateStoreItem';
// import NewOrder from './pages/portal/newOrder';
// import { setLoader } from './components/loader';
import EditUser from './pages/portal/editUser';
import StoreItems from './pages/portal/storeItems';
import ViewStoreItem from './pages/portal/veiwStoreItem';
import NewOrder from './pages/portal/newOrder';
// import ViewOrders from './pages/portal/orders';
// import ViewTransactions from './pages/portal/transactions';
// import ViewTodayTransactions from './pages/portal/todayTransactions';
// import ViewOrder from './pages/portal/viewOrder';
// import ViewTransaction from './pages/portal/viewTransaction';
// import Notifications from './pages/portal/notification';

function App() {
  const dispatch = useDispatch();
  const {sliders} = useSelector((state) => state.sliders);
  

  
// useEffect(() => {
  setLoader();
// }, [])
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes
      // onUpdate={() => window.scrollTo(0, 0)}
      >

        <Route path='/' element={<Home 
        sliders={sliders}
        />} />
        <Route path='contact' element={<ContactUs />} />
        <Route path='about' element={<AboutUs />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='login' element={<Login />} />
        <Route element={<Nav/>} >
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='*' element={<div className='pt-4'> <h3 className=''> PAGE NOT FOUND </h3></div>}  />
            <Route path='/users' element={<Users/>} />
            <Route path='/new_user' element={<CreateUser/>}/>
            <Route path='/user_details' element={<EditUser/>}/>
            <Route path='/add_store_item' element={<AddStoreItem />} />
            <Route path='/store_items' element={<StoreItems />} />
            <Route path='/view_store_item' element={<ViewStoreItem />} />
            <Route path='/update_store_item' element={<UpdateStoreItem />} />
            <Route path='/new_order' element={<NewOrder />} />
        </Route> 

      </Routes>
    </BrowserRouter>
  );
}

export default App;
