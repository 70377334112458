import React, { useEffect, useState } from 'react';
import { Carousel, Button, Card } from 'react-bootstrap';
import Footer from './components/footer';
import Header from './components/Header';
import {ArrowBackIosOutlined, ArrowCircleLeft, ArrowCircleRightSharp, ArrowForward, CalendarMonth} from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Contact from './components/contact';
import { Spinner } from 'react-bootstrap';
import About from './components/about';
import Partners from './components/partners';
import Services from './components/services';
import ClientRemarks from './components/clientRemarks';

const Home = ({news, audioNews, videoNews, newsCategories, books, sliders, services}) => {
  const navigate = useNavigate()
  const [readyState, setReadyState] = useState(false);

  window.start_load();
  setTimeout(() => {
   
    setReadyState(true)
    window.stop_load();
  }, 500)

  function chechReadyState(){
    if(readyState){
      window.start_load();

    }else{
      window.stop_load();
    }
  }
  // useEffect(() => {
  //   news.length > 0 && audioNews.length > 0 && videoNews.length > 0 && 
  // newsCategories.length > 0 && setReadyState(true);
  // }, [])

  useEffect(() => {
    // chechReadyState()
  }, [readyState])
  return readyState && ( 
  <div style={{ overflowX:'hidden' }}>
      <Header activeState={'home'}/>
      {/* <h2> HOME PAGE </h2> */}
      <div className='row slider'>
        
          <div className='col-12'
          
          >
            {/* <h3 className='text-center'> Welcome to UXTECH MEDIA </h3> */}
            {/* <Carousel>

            </Carousel> */}
            <Carousel variant='dark'
            style={{
              height:window.innerWidth > 450? 700 : 600,
              
          }}
            prevIcon={ 
                    <ArrowCircleLeft fontSize='large' className='t-arrow-icon'/>
                    }
            nextIcon={
                <ArrowCircleRightSharp fontSize="large" className="t-arrow-icon" />
            }
            >
              
                {
                    sliders.map(function(productImage,index){
                        return (
                            
                            <Carousel.Item key={index}
                            variant='dark'
                            style={{
                              height:window.innerWidth > 450? 700 : 600,
                              
                          }}
                            >
                            <img
                            className=" w-100 h-100 text-center slide-image"
                            src={productImage.img}
                            alt={`${productImage.title} - Image - ${index}`}
                            />
                            
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
          </div>

      </div>
      {/* Go to Dictionary */}
      {/* <div className='d-flex justify-content-center mb-4'>
          <Button className='text-bold read-btn'
          onClick={() => navigate('/dictionary')}
          >
           <b> GOTO NUPE DICTIONARY</b> <ArrowForward />
          </Button>
      </div> */}
      {/* Upcoming Event  */}
      {/* <div className='d-flex justify-content-evenly bg-primary'> */}
      {/* <div className='row'
      style={{ 
        backgroundColor: 'rgb(35, 35, 82)'
       }}
      >
          <span className=' col mt-2 ms-3'>
            <div className=''>
              <span>
              <CalendarMonth htmlColor='whitesmoke' fontSize='large'/>
              </span>
              <span>
                  <small 
                  className='p-1 '
                  style={{ backgroundColor: 'navy', color: 'white', fontWeight: 700 }}> NEXT </small>
                <i> <p className="news-title text-white" > UPCOMING EVENT </p> </i>
              </span>
            </div>
          </span>
          <span className='col'>
            <p className='news-body text-white'>  Title: </p>
            <p className='news-title text-white'> {eventsData[0].title} </p>
          </span>
          <span className='col'>
            <p className='news-body text-white'>  Date: </p>
            <p className='news-title text-white'> {eventsData[0].date ? eventsData[0].date : eventsData[0].created_at} </p>
          </span>
          <span className='col d-flex align-items-center justify-content-center'>
            <button
            onClick={()=> navigate('events')}
            className='btn btn-lg me-1'
            style={{ backgroundColor: 'navy', color: 'white', fontWeight: 700, fontSize: 15 }}
            // variant='primary'
            >
              All Events
            </button>
          </span>
      </div> */}
      {/* <hr/> */}
      <div style={{ backgroundColor: '' }}>
        
        <Services />
        
       <div id='about'>
          <hr />
          <About />
       </div>
        <ClientRemarks />
        
        <Partners />
       <div id='contact'>
        <hr />
        <Contact />
       </div>
      </div>
      
      <Footer activeState={'home'} />
    </div>
  )
}

export default Home
