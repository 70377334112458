import Axios from "./axios"
import {store} from '../redux/store'
import Cookie from 'react-cookies';
import axios from "axios";
import { userData } from "../redux/slices/userSlice";
// import { transactionData } from "../redux/slices/userSlice";
// import { paymentData } from "../redux/slices/paymentSlice";

import { userList as users } from "../redux/slices/userListSlice";
// import { orders } from "../redux/slices/ordersSlice";
// import { transactions } from "../redux/slices/transactionSlice";
// import { todayTransactions } from "../redux/slices/todayTransactionsSlice";
// import { dailyReviews } from "../redux/slices/dailyReviewSlice";
// import {allTimeReviews} from "../redux/slices/allTimeReviews";
import {storeItems} from "../redux/slices/storeItems";
// import { notifications } from "../redux/slices/notificationSlice";


export const contactUs = async (data) => {
    try {
        window.start_load();
        const res = await Axios.post('api?contactUs', data);
        console.log(res.data);
        return res.data
    } catch (err) {
        console.log(err);
        return err;
    }finally{
        window.stop_load();
    }
}
export const login = async (data) => {
    try{
        window.start_load();
        const res = await Axios.post('/api?login', data);
        if(res.data.success) {
            store.dispatch(userData(res.data.data));
            Cookie.save('token', res.data.token);
            return {
                success: res.data.success,
                message: res.data.message
            }
        
        }else{
            return res.data;
        }
    }catch(err){
        return err.response.data
    }finally{
        window.stop_load();
    }

}
export const uData = async (navigate) => {
    // console.log(token);
 try {
    // window.start_load()
    const response = await Axios.get('api?userData', {
        headers: {
            "Authorization": "Bearer "+Cookie.load('token')
        }
    });
    // console.log(response);
    if(response.data.success){
        // console.log(response);
        store.dispatch(userData(response.data.data));
        
    }else{
        navigate('/login');
    }
 } catch (err) {
    navigate('/login');  
 } 
}
export const createUser = async (data) => {
    try{
        window.start_load();
        const res = await Axios.post('/api?newUser', data, {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(res);
        return res.data;
    }catch(err){
        return err.response.data;
    }finally{
        window.stop_load();
    }
}
export const updateUser = async (data) => {
    try{
        window.start_load();
        const res = await Axios.post('/api?updateUser', data, {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(res);
        return res.data;
    }catch(err){
        console.log(err);
        return err.response.data;
    }finally{
        window.stop_load();
    }
}
export const userList = async () => {
    // console.log('api called')
    try {
        const response = await Axios.get('api?userList', {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(response.data)
        if(response.data.success){
            store.dispatch(users(response.data.data));
            
            // return response.data;
        }
    } catch (err) {
        console.log(err);
    }
        // else{
        //     return 'Failed, to fetch users list. Check your network and reload page. Or Contact support team for help'
        // }
}
// Store Management
export const getStoreItems = async () => {
    try{
        window.start_load()
        const res = await Axios.get('/api?getStoreItems', {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(res.data);
        store.dispatch(storeItems(res.data.data));
        // Cookie.save('store', JSON.stringify(res.data.data));
        return res.data;
    }catch(err){
        console.log(err);
        return err.response.data;
    }finally{
        window.stop_load()
    }
}

// Order Management
export const saveOrder = async () => {
    try{
        window.start_load()
        const res = await Axios.get('/api?saveOrder', {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(res.data);
        return res.data;
    }catch(err){
        console.log(err);
        return err.response.data;
    }finally{
        window.stop_load()
    }
}

   

