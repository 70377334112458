import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Cookie from 'react-cookies';
import { updateUser } from '../../api/api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
const EditUser = () => {
	const navigate = useNavigate();
    const [data, setData] = useState(Cookie.load('editData'));
    const handleEdit = (e) => {
        let v = e.target.value;
        let k = e.target.name;
        setData({...data, [k]:v});

    }
	const handleSubmit = async (e) => {
		e.preventDefault();
		// window.start_load();
		const res = await updateUser(data);
		if(res.success){
			Swal.fire("Success", res.message, 'success');
		}else{
			Swal.fire('Failed', res.message, 'info');
		}
		// window.stop_load();
	}
    // console.log()
  return (
    <div className='m-1 px-2 py-5'
	style={{ 
		backgroundColor: 'whitesmoke',
		height: '99vh'
	 }}
	>
		<h4><b className=" mb-5">Update User Details </b></h4> 
      <div className="card">
		<div className="card-body">
			<form action="" id="newUser" method='post'
			onSubmit={handleSubmit}
			>
				<input type="hidden" name="id" />
				<div className="row">
					<div className="col-md-6 border-right">
						<b className="text-muted">Personal Information</b>
						<div className="form-group">
							<label for="" className="control-label">Name</label>
							<input type="text" name="name" className="form-control form-control-sm" required
                            value={data.name}
							onChange={handleEdit}
                            />
						</div>
						<div className="form-group">
							<label className="control-label">Email</label>
							<input type="email" className="form-control form-control-sm" name="email" required
                            value={data.email}
							onChange={handleEdit}
                            />
							<small id="#msg"></small>
						</div>
						<div className="form-group">
							<label for="" className="control-label">Phone</label>
							<input type="text" name="phone" className="form-control form-control-sm" required
                            value={data.phone}
							onChange={handleEdit}
                            />
						</div>
						<div className="form-group">
							<label className="control-label">Username</label>
							<input type="text" className="form-control form-control-sm" name="username"
                            value={data.username}
							onChange={handleEdit}
                            />
							
						</div>
						<div className="form-group">
							<label className="control-label">Address </label>
							<input type="text" className="form-control form-control-sm" name="address" 
                            value={data.address}
							onChange={handleEdit}
                            />
							
						</div>
					</div>
					<div className="col-md-6">
					<b className="text-muted">Defaults Access Key/Type </b>
						<div className="form-group">
							<label for="" className="control-label">User Type</label>
							<Form.Select size='sm' name="type" id="type" className="form-control"
                            value={data.type}
							onChange={handleEdit}
                            >
								<option value="" >Select user Type... </option>
								<option value="3" >Regular</option>
								<option value="2" >Agent</option>
								<option value="1"> Admin </option>
							</Form.Select>
							<small><i></i></small>
						</div>
                        <div className="form-group">
							<label for="" className="control-label">User Type</label>
							<Form.Select size='sm' name="status" id="type" className="form-control"
                            value={data.status}
							onChange={handleEdit}
                            >
								<option value="" >Account Status... </option>
								<option value="1" >Active</option>
								<option value="0" >Disabled</option>
							</Form.Select>
							<small><i></i></small>
						</div>
					</div>
				</div>
				<hr />
				<div className="col-lg-12 text-right justify-content-center d-flex">
					{/* <input type='submit' className="btn btn-color mr-2" id="submit-btn"
					
					value="Save" /> */}
					 <button className="btn btn-danger btn-lg" type="button"
					 onClick={()=> navigate('/users')}
					 style={{ width: '120px' }}
					 >Back</button>
					 <button className="btn btn-color ms-2 btn-lg"
					  style={{ width: '120px' }}
					 >Update</button> 
					
				</div>
			</form>
		</div>
	</div>
    </div>
  )
}

export default EditUser