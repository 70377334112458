import React, {useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import { createUser } from '../../api/api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const CreateUser = () => {

	const navigate = useNavigate()
	const [data, setData ] = useState({ });
	const [password, setPassword] = useState('');

    const handleChange = (e) => {
        
        setData({ ...data, [e.target.name] : e.target.value});

    }

    const handleSubmit = async (e) => {
      e.preventDefault()
	//   window.start_load();
      // console.log('form submitted.');
      const response = await createUser(data);
      console.log(response);
      if(response.success){
        // Swal.fire('Success', response.message, 'success');///
		Swal.fire({
			title: 'Successful',
			icon:"success",
			text: response.message,
			closeButtonHtml: `<button className="btn btn-color"> Close </button>` 
		})
		e.target.reset();
      }else{
        // Swal.fire('Failed', response.message, 'error')
		Swal.fire({
			title: 'Failed',
			icon:"error",
			text: response.message,
			// showCloseButton: true,
			showConfirmButton: false,
			timer: 5000
			// closeButtonHtml: `<button className=" btn btn-color w-25"> Close </button>` 
		})
      }
	//   window.stop_load();
    }

	useEffect(() => {
		console.log(data);
	}, [data])
  return (
    <div className='m-1 px-2 py-5'
	style={{ 
		backgroundColor: 'whitesmoke',
		height: '99vh'
	 }}
	>
		<h4><b className=" mb-5">Create New User </b></h4> 
      <div className="card">
		<div className="card-body">
			<form action="" id="newUser"
			onSubmit={handleSubmit}
			>
				<input type="hidden" name="id" />
				<div className="row">
					<div className="col-md-6 border-right">
						<b className="text-muted">Personal Information</b>
						<div className="form-group">
							<label for="" className="control-label">Name</label>
							<input
							onChange={handleChange}
							type="text" name="name" className="form-control form-control-sm" required />
						</div>
						<div className="form-group">
							<label className="control-label">Email</label>
							<input 
							onChange={handleChange}
							type="email" className="form-control form-control-sm" name="email" required />
							<small id="#msg"></small>
						</div>
						<div className="form-group">
							<label for="" className="control-label">Phone</label>
							<input 
							onChange={handleChange}
							type="text" name="phone" className="form-control form-control-sm" required />
						</div>
						<div className="form-group">
							<label className="control-label">Username</label>
							<input 
							onChange={handleChange}
							type="text" className="form-control form-control-sm" name="username"  />
							
						</div>
						<div className="form-group">
							<label className="control-label">Address </label>
							<input 
							onChange={handleChange}
							type="text" className="form-control form-control-sm" name="address" />
							
						</div>
					</div>
					<div className="col-md-6">
					<b className="text-muted">Other Details </b>
						<div className="form-group">
							<label for="" className="control-label">User Type</label>
							<Form.Select 
							onChange={handleChange}
							size='sm' name="type" id="type" className="form-control">
								<option value="" >Select user Type... </option>
								<option value="1" >Regular</option>
								<option value="2" >Agent</option>
								<option value="3"> Admin </option>
							</Form.Select>
							<small><i></i></small>
						</div>
						<div className="form-group">
							<label for="" className="control-label">Account Status</label>
							<Form.Select 
							onChange={handleChange}
							size='sm' name="status" id="type" className="form-control">
								<option value="" >Select Status ... </option>
								<option value="1" >Active </option>
								<option value="0" >Disabled</option>
							</Form.Select>
							<small><i></i></small>
						</div>
						<div className="form-group">
						<b className="text-muted"> Select User Password Option </b>
							<select className='form-select'
							onChange={(e) => {
								setPassword(e.target.value);
								if(e.target.value === 'default'){
									setData({...data, password : '0000'})
								}
							}}
							>
								<option className='text-muted'
								style={{ 
									opacity: '60%'
								 }}
								> Manage User Access Key... </option>
								<option value={'default'}> Use Default Password </option>
								<option value={'custom'}> Create a new password </option>
							</select>
							{password==='default' && <small className='text-muted'> Default password is 0000 </small>}
						</div>
						{ password === 'custom' &&
							<>
								<b className="text-muted">Create User Password </b>
								<div className="form-group">
									<label className="label control-label">Create Password</label>
									<input 
									onChange={handleChange}
									type="password" className="form-control form-control-sm" name="password" />
									<small id="pass_match" data-status=''></small>
								</div>
								<div className="form-group">
									<label className="label control-label">Confirm Password</label>
									<input 
									onChange={handleChange}
									type="password" className="form-control form-control-sm" name="cpassword" />
									<small id="pass_match" data-status=''></small>
								</div>	
							</>
						}
					</div>
				</div>
				<hr />
				<div className="col-lg-12 text-right justify-content-center d-flex">
					 <button className="btn btn-danger btn-lg" type="button" 
					 onClick={()=> {
						navigate('/users');
					 }}
					 style={{ width: '120px' }}
					 >Cancel</button>
					 <button className="btn btn-color btn-lg ms-2"
					  style={{ width: '120px' }}
					 >Save</button> 
				</div>
			</form>
		</div>
	</div>
    </div>
  )
}

export default CreateUser