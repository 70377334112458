import { createSlice } from "@reduxjs/toolkit";
import { FaBuilding, FaDollarSign, FaMoneyBill, FaPrint } from "react-icons/fa";
import { FaIntercom } from "react-icons/fa";
const accounting = '/assets/images/services/accounting.jpg';
const telecom = '/assets/images/services/telecom.jpg';
const agriculture = '/assets/images/services/agriculture.jpg';
const construction = '/assets/images/services/construction.jpg';
const contract = '/assets/images/services/contract.jpg';
const crude = '/assets/images/services/crude.jpg';
const propertyDev = '/assets/images/services/propertyDev.jpg';
let photography = '/assets/images/services/photography.jpg';

export const servicesSlice = createSlice({
    name: 'services',

    initialState: {

        payload: [
            {
                // "icon": <FaDollarSign size={50} style={{ borderRadius: '100%', width: '30px', height: '30px' }}/>,
                'icon': accounting,
                "title": "Accounting and Finance",
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
            },
              {
                // "icon": <FaIntercom size={50} style={{ borderRadius: '100%', width: '30px', height: '30px' }} />,
                'icon': telecom,
                "title": "TeleCommunication Services",
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
            },
              {
                "icon": photography,
                "title": "PHOTOGRAPHY AND PRINTING",
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
            },
              {
                // "icon": <FaBuilding size={50} style={{ borderRadius: '100%', width: '30px', height: '30px' }} />,
                'icon': propertyDev,
                "title": "PROPERTY DEVELOPMENT",
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
             },
              {
                // "icon": <FaBuilding size={50} style={{ borderRadius: '100%', width: '30px', height: '30px' }} />,
                'icon': construction,
                "title": "CONSTRUCTION ENGINEERING AND ENVIRONMENTAL SERVICES ",
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
             },
              {
                'icon': agriculture,
                "title": "AGRICULTURE", 
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
            },
              {
                'icon': crude,
                "title": "CRUDE OIL", 
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
            },
              {
                'icon': contract,
                "title": "CONTRACTING AND GENERAL BUSINESS",
                "description": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
            }
        ]
    },
    reducers: {
        services: (state, action) => {
           state.payload = action.payload
        },
    }
});

export const {services} = servicesSlice.actions
export default servicesSlice.reducer