import React, { useEffect, useState } from 'react'
import {Form, FormGroup, Button} from 'react-bootstrap';
import { login } from '../../api/api';
// import { userData } from '../redux/slices/userSlice';
import { useSelector } from 'react-redux';
// import { getState } from '../components/getState';
import Cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const Login = () => {
    const navigate = useNavigate();
    const {start_load, stop_load} = window;
    const [data, setData ] = useState({email: '', password: ''});
    // const uData = useSelector(state => state.user.payload);
    // const uData = getState('user');
    const handleChange = (e) => {
        
        setData({ ...data, [e.target.name] : e.target.value});

    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      // start_load()
      // console.log('form submitted.');
      console.log(data);
      const response = await login(data);
      if(response.success){

        Swal.fire({
          icon: 'success',
          text: response.message,
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          timerProgressBar: true,
          position: 'top-right'
          
        });
        navigate('/dashboard');
      }else{
        console.log(response);
        Swal.fire({
          icon: 'error',
          text: response.message,
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          timerProgressBar: true,
          position: 'top-right'
          
        });
      }
      

    }

    useEffect(() => {
        // console.log(height);
        
    }, [data])
  return (
    <div className='row login py-1 px-4  justify-content-center align-items-center'
    style={{ height: `${window.innerHeight + 'px'}`, overflow: 'hidden' }}
    >
      {/* <h4 className='text-center'> NAMIB PHARMACY STORE MANAGEMENT SYSTEM</h4> */}
      <div
      className='col-lg-4 col-sm-6 col-xs-8 col-xxs-10 my-5 card'
      style={{ 
        
       }}
      >
        {/* <div className='d-flex'> */}
            {/* <img src='/abaafashab.png' height={'50px'} width={'50'}
                className='my-3'
                style={{
                  position: 'fixed',
                  // left: 0, 
                  borderRadius: '50%'
                }}
                /> */}
            <div className='d-flex justify-content-center mt-5'> 
                <h3 className='text-center mt-0'> Sign-in</h3>
            </div>
        {/* </div> */}
        <Form  
        method='post'
        onSubmit={e => handleSubmit(e)}
        >
          
          <Form.Label> Email/Username </Form.Label>
          <FormGroup>
              
              <Form.Control type='text' placeholder='enter email or username'
              name='email'
              value={data.email}
              onChange={ (e) => {handleChange(e)}}
              required
              />
          </FormGroup>
          <Form.Label> Password </Form.Label>
          <FormGroup>
              
              <Form.Control type='password' placeholder='enter password' 
              name='password'
              value={data.password}
              onChange={ (e) => {handleChange(e)}}
              required
              />
          </FormGroup>
          <center>
          <Button
          type='submit' size='lg' className='w-50 mt-3 btn-color mb-5'> Login </Button>
          </center>
        </Form>
      </div>
    </div>
  )
}

export default Login
